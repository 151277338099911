import React from "react";
import { Route, Routes } from "react-router-dom";
import { getSession } from "./api";
import "./App.css";
import { AppContext } from "./context";
import { SignIn } from "./SignIn";
import { SignUp } from "./SignUp";
import { VStack, useColorMode } from "@chakra-ui/react";
import { User } from "./types";
import { Home } from "./Home";
import { NavBar } from "./NavBar";
import { ResetPassword } from "./ResetPassword";
import { ChangePassword } from "./ChangePassword";
import { StripeCheckout } from "./StripeCheckout";

function App() {
  const [user, setUser] = React.useState<null | User | undefined>();
  const { toggleColorMode, colorMode } = useColorMode();

  React.useEffect(() => {
    if (colorMode === "light") {
      toggleColorMode();
    }
  }, [colorMode]);
  React.useEffect(() => {
    const fetchSession = async () => {
      try {
        const data = await getSession();
        if (data) {
          setUser(data);
        } else {
          setUser(null);
        }
      } catch (e) {
        setUser(null);
      }
    };

    fetchSession();
  }, []);

  React.useEffect(() => {
    document.title = "BALLDONTLIE | The Sports API Company";
  }, []);

  if (user === undefined) {
    // Loading session
    return null;
  }

  return (
    <AppContext.Provider value={{ user: user as any, setUser }}>
      <VStack w="100%" h="100%">
        <NavBar />
        <div style={{ flex: 1, width: "100%", padding: 12 }}>
          <Routes>
            {user?.email ? (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/checkout" element={<StripeCheckout />} />
                <Route path="*" element={<Home />} />
              </>
            ) : (
              <>
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/changepassword" element={<ChangePassword />} />
                <Route path="*" element={<SignIn />} />
              </>
            )}
          </Routes>
        </div>
      </VStack>
    </AppContext.Provider>
  );
}

export default App;
