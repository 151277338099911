import { loadStripe } from "@stripe/stripe-js";
import { useAppContext, useMobile } from "./hooks";
import { useEffect, useState } from "react";
import { createCheckoutSessionV2 } from "./api";
import { Tier, Type } from "./types";
import {
  Button,
  Center,
  Heading,
  Spinner,
  useToast,
  VStack,
} from "@chakra-ui/react";
import {
  CheckoutProvider,
  PaymentElement,
  useCheckout,
} from "@stripe/react-stripe-js";
import { datadogRum } from "@datadog/browser-rum";
import { useNavigate } from "react-router-dom";

const stripe = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLIC_KEY ||
    "pk_test_51OdfofGBMDZ8Soj5uPtrq4YXgh0viDLGhzQNjGMLIbbbimvoRL4VG1Ftki6A4Nj08mGaZD5naH6A0CxdpIYcScmY00E4i9CoCs",
  {
    betas: ["custom_checkout_beta_5"],
  }
);

export const StripeCheckout = () => {
  const query = new URLSearchParams(window.location.search);
  const tier = query.get("tier");
  const type = query.get("type");
  const [clientSecret, setClientSecret] = useState<string | null>(null);

  useEffect(() => {
    if (!tier || !type) {
      return;
    }
    const fetchClientSecret = async () => {
      const res = await createCheckoutSessionV2(tier as Tier, type as Type);
      setClientSecret(res.clientSecret);
    };
    fetchClientSecret();
  }, [tier, type]);

  if (!clientSecret) {
    return (
      <Center w="100%" h="100%">
        <Spinner />
      </Center>
    );
  }

  return (
    <CheckoutProvider
      stripe={stripe}
      options={{
        clientSecret,
        elementsOptions: {
          appearance: {
            theme: "night",
            labels: "floating",
            variables: {
              colorPrimary: "#5eead4",
            },
          },
        },
      }}
    >
      <CheckoutForm tier={tier!} type={type!} />
    </CheckoutProvider>
  );
};

const CheckoutForm = ({ tier, type }: { tier: string; type: string }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const checkout = useCheckout();
  const { user } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const isMobile = useMobile();

  useEffect(() => {
    checkout.updateEmail(user?.email!);
    // checkout is not memoized properly
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.email]);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const result = await checkout.confirm({ redirect: "if_required" });
      if (result.type === "error") {
        toast({
          title: "Error",
          description: result.error.message,
          status: "error",
          isClosable: true,
        });
      } else {
        datadogRum.addAction("stripe_callback", {
          type,
          tier,
          cookie: document.cookie,
          email: user?.email,
        });
        const dollars = tier === "paid" ? 9.99 : 39.99;
        (window as any).gtag("event", "conversion", {
          send_to: "AW-16760575486/17bXCKTZhOQZEP6riLg-",
          value: dollars,
          currency: "USD",
          transaction_id: user?.email,
        });
        toast({
          title: "Purchase Complete",
          description:
            "It may take a few minutes for changes to take effect. Please refresh the page in a minute.",
          status: "success",
          isClosable: true,
          duration: null,
        });
        navigate("/", { replace: true });
      }
    } catch (e) {
      toast({
        title: "Error",
        description: "Please contact support at hello@balldontlie.io for help",
        status: "error",
        isClosable: true,
        duration: null,
      });
    }
    setIsLoading(false);
  };

  const w = isMobile ? "100%" : "75%";

  return (
    <VStack w="100%" h="100%" align="center" justify="center" gap={4}>
      <VStack w={w} align="flex-start">
        <Heading mb={0}>{type.toUpperCase()}</Heading>
        {type === "all_access" ? (
          <Heading size="md">$89.99/mo</Heading>
        ) : (
          <Heading size="md">
            {tier === "paid" ? "ALL-STAR" : "GOAT"}{" "}
            {tier === "paid" ? "$9.99/mo" : "$39.99/mo"}
          </Heading>
        )}
      </VStack>
      <form style={{ width: w }}>
        <PaymentElement options={{ layout: "accordion" }} />
      </form>

      <Button
        w={w}
        colorScheme="teal"
        disabled={isLoading}
        isLoading={isLoading}
        onClick={handleConfirm}
      >
        Confirm Payment
      </Button>
    </VStack>
  );
};
