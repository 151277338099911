import React from "react";
import { signUp } from "./api";
import { useAppContext } from "./hooks";
import {
  Button,
  Center,
  Heading,
  Input,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import validator from "validator";

export function SignUp() {
  const { setUser } = useAppContext();
  const navigate = useNavigate();
  const toast = useToast();

  const emailRef = React.useRef<any>(null);
  const passwordRef = React.useRef<any>(null);
  const passwordConfRef = React.useRef<any>(null);
  const [isLoading, setLoading] = React.useState<boolean>(false);

  const onSubmit = React.useCallback(async () => {
    const email = emailRef.current?.value?.toLowerCase();
    const password = passwordRef.current?.value;
    const passwordConf = passwordConfRef.current?.value;

    if (!validator.isEmail(email)) {
      toast({
        title: "Invalid email",
        status: "error",
        isClosable: true,
      });
      return;
    }

    if (password !== passwordConf) {
      toast({
        title: "Passwords don't match",
        status: "error",
        isClosable: true,
      });
    } else if (email && password && passwordConf) {
      try {
        setLoading(true);
        const res = await signUp({ email, password });
        setUser(res);
        navigate("/");
      } catch {
        setLoading(false);
        toast({
          title: "Unexpected error",
          status: "error",
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "All fields are required",
        status: "error",
        isClosable: true,
      });
    }
  }, [emailRef, passwordRef, passwordConfRef, setUser]);

  React.useEffect(() => {
    const listener = (e: any) => {
      if (e.key?.toLowerCase() === "enter") {
        onSubmit();
      }
    };
    window.removeEventListener("keydown", listener);
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [onSubmit]);

  return (
    <Center w="100%" h="100%">
      <VStack w={["90%", "50%"]} spacing={4}>
        <Heading>CREATE AN ACCOUNT</Heading>
        <Input ref={emailRef} placeholder="email" />
        <Input type="password" ref={passwordRef} placeholder="password" />
        <Input
          type="password"
          ref={passwordConfRef}
          placeholder="confirm password"
        />
        <Button
          w="100%"
          colorScheme="teal"
          onClick={onSubmit}
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          Submit
        </Button>
      </VStack>
    </Center>
  );
}
