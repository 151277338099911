import axios from "axios";
import { Tier, Type, User } from "./types";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST || "http://localhost:8080/",
  withCredentials: true,
});

export const getSession = async (): Promise<User> => {
  const res = await instance.get("/session");
  return res.data;
};

export const signIn = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const res = await instance.post("/signin", { email, password });
  return res.data;
};

export const resetPassword = async ({ email }: { email: string }) => {
  const res = await instance.post("/reset-password", { email });
  return res.data;
};

export const changePassword = async ({
  token,
  password,
}: {
  token: string;
  password: string;
}) => {
  const res = await instance.post("/change-password", { token, password });
  return res.data;
};

export const signUp = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const res = await instance.post("/signup", { email, password });
  return res.data;
};

export const signOut = async () => {
  const res = await instance.post("/signout");
  return res.data;
};

export const createCheckoutSession = async (
  tier: Tier,
  type: Type,
  gaw?: string | null,
  gau?: string | null,
  ggs?: string | null
) => {
  // Translate because + is not allowed in URL
  let tierTranslated = tier === "paid+" ? "paid_plus" : tier;
  const res = await instance.post(
    `/stripe/create-checkout-session?tier=${tierTranslated}&type=${type}&gaw=${gaw}&gau=${gau}&ggs=${ggs}`,
    {
      google: {
        gaw,
        gau,
        ggs,
      },
    }
  );
  return res.data;
};

export const createManageSubscriptionSession = async () => {
  const res = await instance.post("/stripe/create-portal-session");
  return res.data;
};

export const updateTier = async (tier: Tier, type: Type) => {
  let tierTranslated = tier === "paid+" ? "paid_plus" : tier;
  const res = await instance.post("/stripe/update-plan", {
    tier: tierTranslated,
    type,
  });
  return res.data;
};

export const createCheckoutSessionV2 = async (tier: Tier, type: Type) => {
  const res = await instance.post<{ clientSecret: string }>(
    "/stripe/v2/create-checkout-session",
    {
      tier: tier === "paid+" ? "paid_plus" : tier,
      type,
    }
  );
  return res.data;
};
